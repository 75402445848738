import { Box } from '@mui/material';

interface Props {
  text: string;
  ariaLabel: string;
}

const SearchFormHeading = ({ text, ariaLabel }: Props): React.JSX.Element => {
  const sx = {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'primary.main',
    mt: 0,
    mb: 1,
  };

  return (
    <Box component="h2" aria-label={ariaLabel} sx={sx}>
      {text}
    </Box>
  );
};

export default SearchFormHeading;

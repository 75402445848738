import {
  FaAddressBook,
  FaAddressCard,
  FaAt,
  FaBell,
  FaBellSlash,
  FaCheck,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaCity,
  FaClinicMedical,
  FaComments,
  FaEnvelopeOpenText,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaExternalLinkAlt,
  FaExternalLinkSquareAlt,
  FaFileCode,
  FaFileExport,
  FaFileImport,
  FaFileMedical,
  FaFilePdf,
  FaFileWord,
  FaHandPaper,
  FaHandPointLeft,
  FaHandPointRight,
  FaLaptopMedical,
  FaLock,
  FaMailBulk,
  FaMapMarkedAlt,
  FaMapMarkerAlt,
  FaMinus,
  FaPaperPlane,
  FaPen,
  FaPercent,
  FaPhoneAlt,
  FaPlus,
  FaPlusCircle,
  FaProjectDiagram,
  FaRedo,
  FaSave,
  FaSearch,
  FaShare,
  FaShareSquare,
  FaSignOutAlt,
  FaSms,
  FaStar,
  FaStethoscope,
  FaStickyNote,
  FaTable,
  FaTasks,
  FaTicketAlt,
  FaTimes,
  FaUnlockAlt,
  FaUser,
  FaUserAltSlash,
  FaUserMd,
  FaUserPlus,
  FaUsers,
  FaVial,
  FaWpforms,
} from 'react-icons/fa';
import { green700, grey600, red700, yellow900 } from 'theme';

import { IconData } from './interface';

export const iconMap = new Map<number, IconData>([
  [1, { content: FaMailBulk, color: red700 }],
  [2, { content: FaMailBulk, color: green700 }],
  [3, { content: FaHandPaper, color: red700 }],
  [4, { content: FaHandPaper, color: green700 }],
  [5, { content: FaStethoscope, color: green700 }],
  [6, { content: FaStethoscope, color: red700 }],
  [7, { content: FaBell, color: green700 }],
  [8, { content: FaBellSlash, color: red700 }],
  [9, { content: FaRedo }],
  [10, { content: FaPhoneAlt }],
  [11, { content: FaAt }],
  [12, { content: FaShare }],
  [13, { content: FaFileWord }],
  [14, { content: FaUserAltSlash, color: red700 }],
  [15, { content: FaUserAltSlash, color: green700 }],
  [16, { content: FaHandPointLeft, color: red700 }],
  [17, { content: FaHandPointRight, color: green700 }],
  [19, { content: FaComments }],
  [20, { content: FaUsers }],
  [21, { content: FaUserPlus }],
  [22, { content: FaUserMd }],
  [23, { content: FaAddressBook }],
  [24, { content: FaCity }],
  [25, { content: FaClinicMedical }],
  [26, { content: FaMapMarkedAlt }],
  [27, { content: FaPercent }],
  [28, { content: FaEnvelopeOpenText }],
  [29, { content: FaAddressCard }],
  [30, { content: FaTable }],
  [31, { content: FaFilePdf, color: red700 }],
  [32, { content: FaFileExport }],
  [33, { content: FaFileImport }],
  [34, { content: FaFileMedical }],
  [35, { content: FaMapMarkerAlt }],
  [36, { content: FaVial }],
  [37, { content: FaShare }],
  [38, { content: FaStethoscope, color: red700 }],
  [39, { content: FaStethoscope, color: green700 }],
  [40, { content: FaEnvelopeOpenText }],
  [41, { content: FaSms }],
  [42, { content: 'HN', type: 'avatar' }], // helsenorge.no
  [43, { content: FaWpforms }],
  [44, { content: FaPaperPlane }],
  [45, { content: FaVial, color: red700 }],
  [46, { content: FaVial, color: green700 }],
  [47, { content: FaVial, color: grey600 }],
  [48, { content: FaShareSquare }],
  [49, { content: FaVial }],
  [50, { content: FaLaptopMedical }],
  [51, { content: FaTicketAlt }],
  [52, { content: FaCheck, color: green700 }],
  [53, { content: FaMinus, color: red700 }],
  [55, { content: FaExternalLinkAlt }],
  [56, { content: FaTasks }],
  [57, { content: FaLock }],
  [58, { content: FaUnlockAlt }],
  [59, { content: FaPlus }],
  [60, { content: FaExternalLinkSquareAlt }],
  [61, { content: FaTasks }],
  [62, { content: FaPlusCircle }],
  [63, { content: FaTimes }],
  [64, { content: FaExclamationTriangle }],
  [65, { content: FaProjectDiagram }],
  [66, { content: FaPen }],
  [67, { content: FaChevronDown }],
  [68, { content: FaChevronUp }],
  [69, { content: FaSignOutAlt }],
  [70, { content: FaChevronRight }],
  [71, { content: FaChevronLeft }],
  [72, { content: FaSearch }],
  [73, { content: FaExclamationCircle }],
  [74, { content: FaStar, color: yellow900 }],
  [75, { content: FaProjectDiagram, color: red700 }],
  [76, { content: FaProjectDiagram }],
  [77, { content: FaProjectDiagram, color: green700 }],
  [78, { content: FaProjectDiagram, color: grey600 }],
  [79, { content: FaSave }],
  [80, { content: FaFileCode }],
  [81, { content: FaStethoscope }],
  [82, { content: FaStickyNote }],
  [83, { content: FaUser }],
]);

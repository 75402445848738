export enum HvemOgTidspunktParam {
  ingen = 'ingen',
  min = 'min',
  detaljer = 'detaljer',
}

export enum DybdeParam {
  ingen = 'ingen',
  min = 'min',
  detaljer = 'detaljer',
}

export type DetailParams = {
  hvemogtidspunkt?: HvemOgTidspunktParam;
  dybde?: DybdeParam;
};

export type GyldigPeriode = {
  gyldigFra: string;
  gyldigTil?: string;
};

export enum SearchType {
  ByCriterias,
  DirectLookupByFnr,
  DirectLookupSnuten,
  DirectLookupMeldingId,
  DirectLookupRekvNr,
  DirectLookupPersonId,
}
export interface Params {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | number[]
    | string[]
    | undefined;
}

import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet, httpPost } from 'utils/http';

import { DeltakerIfobtRundeInfoDto, DemaskerTypeEnum } from './dto';
import {
  DeltakerService,
  GetAdresseHistorikkByDeltakerId,
  GetDeltakerByFødselsnummer,
  GetDeltakerById,
  GetDeltakerBySøkDetaljer,
  GetDeltakerIdByMeldingId, GetDeltakerIdByPersonId,
  GetDeltakerIdByRekvisisjonsnummer,
  GetDeltakerIdBySnutenKryptertFødselsnummer,
  GetDeltakerInfoDemaskert,
  GetDeltakerLog,
  GetHenvendelserByDeltakerId,
  GetHenvisningerByDeltakerId,
  GetIfobtRunderInfo,
  GetKRRInfoByDeltakerId,
  GetSnutenKryptertFødselsnummerByDeltakerId,
  PostDeltaker,
  PostDeltakeroppfølging,
  PostDemaskertDeltaker,
  PostKoloskopirapportForDeltaker,
  PostSearchForPersonFnr,
} from './interface';

const basePath = 'deltakere';

const postDeltaker: PostDeltaker = async (
  fødselsNr,
  visningsgrunnId,
  årsak
) => {
  try {
    const path = buildPath(basePath);
    return await httpPost(path, { fødselsNr, visningsgrunnId, årsak });
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Kunne ikke legge til deltaker med angitt fødselsnummer: ${
        (error as FetchResponseError).message
      }`
    );
  }
};

const getDeltakerById: GetDeltakerById = async (deltakerId, signal?) => {
  const path = buildPath(`${basePath}/${deltakerId}/detaljer`);
  return httpGet(path, signal).catch((error: FetchResponseError) => {
    if (error.statuscode === 404) {
      return null;
    }
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil med henting av Intern-ID: ${deltakerId}`
    );
  });
};

const getIfobtRunderInfo: GetIfobtRunderInfo = async (deltakerId, signal?) => {
  const fullPath = buildPath(`${basePath}/${deltakerId}/ifobtRunderInfo`);

  try {
    return await httpGet<DeltakerIfobtRundeInfoDto[]>(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av IfobtRunder for deltakerId ${deltakerId} feilet`
    );
  }
};

const getHenvisningerByDeltakerId: GetHenvisningerByDeltakerId = async (
  deltakerId,
  signal?
) => {
  const fullPath = buildPath(`${basePath}/${deltakerId}/henvisninger`);

  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av Koloskopihenvisninger for deltakerId ${deltakerId} feilet`
    );
  }
};

export const getDeltakerInfoDemaskert: GetDeltakerInfoDemaskert = async (
  deltakerId,
  demaskertType = DemaskerTypeEnum.alle
) => {
  const path = buildPath(
    `${basePath}/${deltakerId}/demasker?demaskertType=${demaskertType}`
  );
  return httpGet(path).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppstod en feil: ${error.message}`
    );
  });
};
export const postDemaskertDeltaker: PostDemaskertDeltaker = async (
  deltakerId,
  årsakId
) => {
  const path = buildPath(`${basePath}/${deltakerId}/demasker`);
  return httpPost(path, { id: årsakId }).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppstod en feil: ${error.message}`
    );
  });
};
export const getDeltakerLog: GetDeltakerLog = async (screenItId, signal?) => {
  const fullPath = buildPath(`${basePath}/${screenItId}/logg`);

  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av deltakerutsending detaljer: ${error.message}`
    );
  });
};

export const getDeltakerByFødselsnummer: GetDeltakerByFødselsnummer = async (
  data,
  params?
) => {
  const path = buildPath(`${basePath}/fodselsnr`, params);
  return httpPost(path, data)
    .then((response) => response)
    .catch((error: FetchResponseError) => {
      if (error.statuscode === 404) {
        return null;
      }
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto ved søk på person med fødselsnummer ${data.fødselsnr}: ${error.message}`
      );
    });
};

export const getDeltakerBySøkDetaljer: GetDeltakerBySøkDetaljer = async (
  values,
  signal?
) => {
  const path = buildPath(`${basePath}/sok`, values);
  return httpGet(path, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved søk på person: ${error.message}`
    );
  });
};

export const postDeltakeroppfølging: PostDeltakeroppfølging = async (
  deltakerId,
  oppfølgingDto
) => {
  const path = buildPath(`${basePath}/${deltakerId}/oppfolginger`);
  return httpPost(path, oppfølgingDto).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved oppretting av oppfølging: ${error.message}`
    );
  });
};

export const getHenvendelserByDeltakerId: GetHenvendelserByDeltakerId = async (
  deltakerId,
  signal?
) => {
  const path = buildPath(`${basePath}/${deltakerId}/henvendelser`);
  return httpGet(path, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved henting av henvendelser for deltaker: ${deltakerId}, message: ${error.message}`
    );
  });
};

export const getAdresseHistorikkByDeltakerId: GetAdresseHistorikkByDeltakerId =
  async (deltakerId, signal?) => {
    const path = buildPath(`${basePath}/${deltakerId}/adresser`);
    return httpGet(path, signal).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `Det oppsto en feil ved henting av adressehistorikk for deltaker: ${deltakerId}, message: ${error.message}`
      );
    });
  };

export const getKRRInfoByDeltakerId: GetKRRInfoByDeltakerId = async (
  deltakerId,
  signal?
) => {
  const path = buildPath(`${basePath}/${deltakerId}/krr-persontjenesten`);
  return httpGet(path, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved henting av Kontakt- og reservasjonsregisteret for deltaker: ${deltakerId}, message: ${error.message}`
    );
  });
};

export const getSnutenKryptertFødselsnummerByDeltakerId: GetSnutenKryptertFødselsnummerByDeltakerId =
  async (deltakerId, signal?) => {
    const path = buildPath(
      `${basePath}/${deltakerId}/snuten-kryptert-fodselsnummer`
    );
    return httpGet(path, signal).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `Det oppsto en feil ved henting av SNUTEN-kryptert fødselsnummer for deltaker: ${deltakerId}, message: ${error.message}`
      );
    });
  };

export const getDeltakerIdBySnutenKryptertFødselsnummer: GetDeltakerIdBySnutenKryptertFødselsnummer =
  async (snutenKryptertFnr) => {
    const path = buildPath(`${basePath}/deltaker-id-by-kfnr`);
    return httpPost(path, { snutenKryptertFødselsnr: snutenKryptertFnr }).catch(
      (error: FetchResponseError) => {
        throw addMessageToExsistingFetchError(
          error,
          `Det oppsto en feil ved henting av deltaker med SNUTEN-kryptert fødselsnummer: ${snutenKryptertFnr}, message: ${error.message}`
        );
      }
    );
  };

export const getDeltakerIdByMeldingId: GetDeltakerIdByMeldingId = async (
  meldingId: string
) => {
  const path = buildPath(`${basePath}/${meldingId}/deltaker-id-by-melding-id`);
  return httpGet(path).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved henting av deltaker med Melding ID: ${meldingId}, message: ${error.message}`
    );
  });
};

export const getDeltakerIdByRekvisisjonsnummer: GetDeltakerIdByRekvisisjonsnummer =
  async (rekvisisjonsnummer: string) => {
    const path = buildPath(
      `${basePath}/${rekvisisjonsnummer}/deltaker-id-by-rekvisisjonsnummer`
    );
    return httpGet(path).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `Det oppsto en feil ved henting av deltaker med Rekvisisjonsnummer: ${rekvisisjonsnummer}, message: ${error.message}`
      );
    });
  };

export const getDeltakerIdByPersonId: GetDeltakerIdByPersonId =
  async (personId: string) => {
    const path = buildPath(
      `${basePath}/${personId}/deltaker-id-by-person-id`
    );
    return httpGet(path).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `Det oppsto en feil ved henting av deltaker med personId: ${personId}, message: ${error.message}`
      );
    });
  };

export const postSearchForPersonFnr: PostSearchForPersonFnr = async (
  fnr: string
) => {
  const path = buildPath(`${basePath}/folkeregisteret/fodselsnr`);
  try {
    return await httpPost(path, {
      fødselsnr: fnr,
    });
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av informasjon for person med fnr ${fnr} feilet, ${
        (error as FetchResponseError).message
      }`
    );
  }
};

export const postKoloskopirapportForDeltaker: PostKoloskopirapportForDeltaker =
  async (deltakerId, koloskopirapport) => {
    const path = buildPath(`${basePath}/${deltakerId}/koloskopi/rapport`);
    try {
      return await httpPost(path, koloskopirapport);
    } catch (error) {
      throw addMessageToExsistingFetchError(
        error as FetchResponseError,
        (error as FetchResponseError).message
      );
    }
  };

export default {
  postDeltaker,
  getIfobtRunderInfo,
  getHenvisningerByDeltakerId,
  getDeltakerById,
  getDeltakerInfoDemaskert,
  getDeltakerLog,
  getDeltakerByFødselsnummer,
  getDeltakerBySøkDetaljer,
  postDeltakeroppfølging,
  getHenvendelserByDeltakerId,
  getAdresseHistorikkByDeltakerId,
  getKRRInfoByDeltakerId,
  getSnutenKryptertFødselsnummerByDeltakerId,
  getDeltakerIdBySnutenKryptertFødselsnummer,
  getDeltakerIdByMeldingId,
  getDeltakerIdByRekvisisjonsnummer,
  postDemaskertDeltaker,
  postSearchForPersonFnr,
  postKoloskopirapportForDeltaker,
} as DeltakerService;

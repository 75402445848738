//Skopører
export const HELSEPERSONELL_LANDINGSIDE = 'helsepersonell';
export const OPPRETT_HELSEPERSONELL_PATH = 'opprett-helsepersonell';
export const REDIGER_HELSEPERSONELL_PATH = 'rediger-helsepersonell';
export const SKOPØR_INFORMASJON = `informasjon`;
export const SKOPØR_RESERVASJON = `reservasjon`;
export const SKOPØR_RESERVERTE = `reserverte`;
export const HELSEPERSONELL_ROLLER = `helsepersonell-roller`;
//Administrasjon
export const ADMINISTRASJON_LANDINGSIDE = 'administrasjon';
export const ADMINISTRASJON_KOLOSKOPIRAPPORT_PATH =
  'administrasjon-koloskopirapport';
export const ADMINISTRASJON_PERSONTJENESTEN_SEARCH_PATH =
  'persontjenesten-search';
export const ADMINISTRASJON_PERSONTJENESTEN_UPDATE_PATH =
  'persontjenesten-udpate';
export const ADMINISTRASJON_INKLUSJON_KANDIDAT_SEARCH_PATH =
  'inklusjon-kandidat-search';
export const ADMINISTRASJON_ANDVORD_STATUS_SEARCH_PATH =
  'andvord-status-search';

//Utsendingspause
export const UTSENDINGSPAUSE_PAGE_PATH = 'utsendingspause';
export const UTSENDINGSPAUSE_REDIGER_PATH = 'rediger-utsendingspause';
export const UTSENDINGSPAUSE_OPPRETT_PATH = 'opprett-utsendingspause';
//Enheter
export const ENHETER_PATH = 'enheter';
export const OPPRETT_ENHET_PATH = 'opprett-enhet';
export const REDIGER_ENHET_PATH = 'rediger-enhet';
//Invitasjon og inklusjon
export const INVITASJON_OG_INKLUSJON_PATH = 'invitasjon-og-inklusjon';
export const INVITASJON_OG_INKLUSJON_REDIGER_PATH = 'rediger';
export const INVITASJON_OG_INKLUSJON_INKLUSJON_PATH = 'inklusjon';
export const INVITASJON_OG_INKLUSJON_KVITTERING_PATH = 'kvittering';

//Sentraladmin Søk
export const SENTRALADMINSØK_ROUTE = 'sentraladminsok';
export const SENTRALADMINSØK_DELTAKER_PATH = 'deltaker';
export const SENTRALADMINSØK_SENTER_PATH = 'senter';
export const SENTRALADMINSØK_INTERVALL_PATH = 'intervall';
export const SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH =
  'inkl-provesvar-henvisning';

//Hendelsekontroll
export const HENDELSEKONTROLL_LANDINGSIDE_PATH = 'hendelsekontroll';
export const HENDELSEKONTROLL_HENDELSELOGG = 'hendelselogg';
export const HENDELSEKONTROLL_HENDELSELOGG_PATH = `${HENDELSEKONTROLL_LANDINGSIDE_PATH}/${HENDELSEKONTROLL_HENDELSELOGG}`;
export const HENDELSEKONTROLL_KVITTERING = 'kvitteringer';
export const HENDELSEKONTROLL_KVITTERING_PATH = `${HENDELSEKONTROLL_LANDINGSIDE_PATH}/${HENDELSEKONTROLL_KVITTERING}`;
export const HENDELSEKONTROLL_LOGGKONTROLL = 'loggkontroll';
export const HENDELSEKONTROLL_LOGGKONTROLL_PATH = `${HENDELSEKONTROLL_LANDINGSIDE_PATH}/${HENDELSEKONTROLL_LOGGKONTROLL}`;
export const HENDELSEKONTROLL_LOGGKONTROLL_MELDING = 'loggkontroll-melding';
export const HENDELSEKONTROLL_LOGGKONTROLL_MELDING_PATH = `${HENDELSEKONTROLL_LANDINGSIDE_PATH}/${HENDELSEKONTROLL_LOGGKONTROLL_MELDING}`;

//Eksterne meldinger
export const SENTRALADMIN_EKSTERNE_MELDINGER = 'eksterne-meldinger';
export const SENTRALADMIN_HELSEMELDINGER = `${SENTRALADMIN_EKSTERNE_MELDINGER}/helsemeldinger`;
export const SENTRALADMIN_HELSEMELDINGER_SOK = `${SENTRALADMIN_HELSEMELDINGER}/sok`;
export const SENTRALADMIN_HELSEMELDINGER_FHIR_SOK = `${SENTRALADMIN_EKSTERNE_MELDINGER}/fhir/sok`;
export const SENTRALADMIN_HELSEMELDINGER_FEIL = `${SENTRALADMIN_EKSTERNE_MELDINGER}/helsemeldinger-feil`;
export const SENTRALADMIN_HELSEMELDINGER_FEIL_SOK = `${SENTRALADMIN_HELSEMELDINGER_FEIL}/sok`;
export const SENTRALADMIN_LOGISITKKPARTNER = `${SENTRALADMIN_EKSTERNE_MELDINGER}/logistikkpartner`;
export const SENTRALADMIN_LOGISITKKPARTNER_SOK = `${SENTRALADMIN_LOGISITKKPARTNER}/sok`;
export const SENTRALADMIN_LOGISITKKPARTNER_FEIL = `${SENTRALADMIN_LOGISITKKPARTNER}/feil`;

//Annet
export const SENTRALADMINSOK_UTSENDINGER_PATH = 'utsendinger';
export const DOKUMENTER_PATH = 'dokumenter';
export const UTSENDINGTYPE_PATH = 'utsendingtyper';
export const SYSTEMTABELLER_PATH = 'systemtabeller';
export const SENTERTILKNYTNING_PATH = 'sentertilknytning';
export const PERSONOPPSLAG_PATH = 'personoppslag';
export const PERSON_PATH = 'person';
export const KAPASITET_PATH = 'kapasitet';
export const BRUKERE_PATH = 'brukere';
export const BRUKER_PATH = 'bruker';
export const OPPFOLGING = 'oppfolging';
export const EKSTERNT_PERSONSOK_PATH = 'eksternt-personsok';
export const SYSTEMINFORMASJON_PATH = 'systeminformasjon';
export const PROFIL_PATH = 'profil';
export const LOGGET_UT_PATH = 'logget-ut';
export const STYLE_GUIDE_PATH = 'sg';

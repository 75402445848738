export type DashboardSummeryData = {
  count?: number;
  weekCount?: number;
  monthCount?: number;
};

export type KoloskopirapportOverviewInfo = {
  senterNavn: string;
  mottattTidspunkt: string;
  daysLastReceived: number;
};

export type OppfølgingOverviewInfo = {
  oppfolgingTypeId: number;
  ikkePabegynt: number;
  underArbeid: number;
};

export type UtsendingspauseOverviewInfo = {
  utsendingTypeId: number;
  fraOgMedDato: string;
  tilOgMedDato: string;
};

export type HelsemeldingOverviewInfo = {
  helsemeldingTypeId: number;
  inn: number;
  ut: number;
};

export type HelsemeldingFeilOverviewInfo = {
  helsemeldingTypeId: number;
  helsemeldingFeiltypeId: number;
  messages: number;
};

export type AutomatiskInklusjonInfo = {
  innstillinger: string;
  mandag: boolean;
  tirsdag: boolean;
  onsdag: boolean;
  torsdag: boolean;
  fredag: boolean;
  lørdag: boolean;
};

export type DashboardData = {
  inklusjon: DashboardSummeryData;
  invitasjon: DashboardSummeryData;
  provesett: DashboardSummeryData;
  provesvar: DashboardSummeryData;
  henvisning: DashboardSummeryData;
  koloskopirapport: DashboardSummeryData;
  koloskopirapportInfo: KoloskopirapportOverviewInfo[];
  oppfolgingInfo: OppfølgingOverviewInfo[];
  utsendingspauseInfo: UtsendingspauseOverviewInfo[];
  helsemelding: HelsemeldingOverviewInfo[];
  helsemeldingFeil: HelsemeldingFeilOverviewInfo[];
  automatiskInklusjon: AutomatiskInklusjonInfo[];
};

export type DashboardProfil = {
  brukernavn: string;
  fornavnOgMellomnavn: string;
  etternavn: string;
  epost: string;
  roller: DashboardProfilRolle[];
};

export type DashboardProfilRolle = {
  navn: string;
  rettigheter: string[];
};


export const dashboardDataInitialState = {
  inklusjon: {},
  invitasjon: {},
  provesett: {},
  provesvar: {},
  henvisning: {},
  koloskopirapport: {},
  koloskopirapportInfo: [],
  oppfolgingInfo: [],
  helsemelding: [],
  helsemeldingFeil: [],
  automatiskInklusjon: [],
};
import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpPost } from 'utils/http';

import { GetAndvordSearchResponse } from './interface';
import { AndvordSearchResponseDto } from './dto';

const basePath = 'logistikkpartnerforsendelser/andvord';

export const getAndvordSearchResponse: GetAndvordSearchResponse = async (
  searchParams,
  signal?
): Promise<AndvordSearchResponseDto> => {
  const fullPath = buildPath(`${basePath}/search`);

  return httpPost(fullPath, searchParams).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(error, error.message);
  });
};

import { Grid } from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import {
  LoadingComponent,
  PageHeaderHidden,
  ScrPage,
  ScrWrapper,
} from 'components';
import ResultArea from 'features/sentraladmin/eksterneMeldinger/components/result/ResultArea';
import { useState } from 'react';

import { ResultType } from 'features/sentraladmin/eksterneMeldinger/components/result/interface';
import { useSendData } from 'utils/hooks';

import { AndvordStatusSearchFields, FormState } from './component/interface';
import { dispatchNotification, initialFormState } from './component/utils';
import AndvordStatusSearchForm from './component/AndvordStatusSearchForm';
import { getAndvordSearchResponse } from 'services/andvordSearch/andvordSearchService';
import { AndvordSearchResponseDto } from 'services/andvordSearch/dto';

const AndvordStatusSearchPage = () => {
  const {
    send,
    status: searchStatus,
    data: andvordSearchData,
    resetData,
  } = useSendData<AndvordSearchResponseDto>();

  const [formState, setFormState] = useState<FormState>(initialFormState);

  const dispatch = useAppDispatch();

  const reset = () => {
    setFormState(initialFormState);
    resetData();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    resetData();
    if (
      formState.logistikkpartnerForsendelseId.length === 0 &&
      formState.meldingId.length === 0
    ) {
      dispatchNotification(
        dispatch,
        'En verdi kreves for å søke',
        'error',
        true
      );
    } else {
      send(() =>
        getAndvordSearchResponse({
          logistikkpartnerForsendelseId:
            formState.logistikkpartnerForsendelseId,
          meldingId: formState.meldingId,
        })
      ).catch((error) => {
        dispatchNotification(dispatch, error.message, 'error');
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const name = event?.target?.name as keyof AndvordStatusSearchFields;

    if (name === 'logistikkpartnerForsendelseId') {
      setFormState({
        ...formState,
        logistikkpartnerForsendelseId: value,
        meldingId: '',
      });
    } else if (name === 'meldingId') {
      setFormState({
        ...formState,
        logistikkpartnerForsendelseId: '',
        meldingId: value,
      });
    }
  };

  return (
    <ScrPage headline="Andvord Status Søk" iconId={57}>
      <Grid container>
        <Grid container>
          <Grid container item xs={12}>
            <ScrWrapper sx={{ mt: 1.5, px: { xs: 4, md: 2, xl: 4 }, py: 2 }}>
              <AndvordStatusSearchForm
                isSearching={searchStatus.loading}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                reset={reset}
                formState={formState}
              />
            </ScrWrapper>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <LoadingComponent showLoading={searchStatus.loading}>
          {andvordSearchData && (
            <ScrWrapper
              component="section"
              ariaLabel="søkeresultat"
              sx={{
                px: { xs: 4, md: 2, xl: 2 },
                pb: { xs: 4, md: 2, xl: 2 },
                py: { xs: 4, md: 2, xl: 2 },
              }}>
              <PageHeaderHidden
                level="h2"
                description="Søkeresultater brukersøk"
              />
              <ResultArea
                melding={andvordSearchData.response}
                type={ResultType.JSON}></ResultArea>
            </ScrWrapper>
          )}
        </LoadingComponent>
      </Grid>
    </ScrPage>
  );
};

export default AndvordStatusSearchPage;

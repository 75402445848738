import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { ScrIcon } from 'components';

interface Props {
  buttonIdNamePrefix: string;
  disabled?: boolean;
  isSearching?: boolean;
  resetLabel?: string;
  submitLabel?: string;
  submitIconId?: number;
}

const SearchFormButtons = ({
  disabled,
  buttonIdNamePrefix,
  isSearching,
  resetLabel,
  submitLabel,
  submitIconId,
}: Props): React.JSX.Element => {
  return (
    <>
      <Button
        id={`${buttonIdNamePrefix}_reset`}
        name={`${buttonIdNamePrefix}_reset`}
        disabled={disabled ?? isSearching}
        size="large"
        type="reset"
        sx={{ mr: 1 }}
        aria-label="nullstill søkeskjema">
        {resetLabel ? resetLabel : 'Nullstill'}
      </Button>
      <LoadingButton
        size="large"
        id={`${buttonIdNamePrefix}_søk`}
        name={`${buttonIdNamePrefix}_søk`}
        sx={{ mr: 1 }}
        color="primary"
        type="submit"
        loading={isSearching}
        variant="contained"
        disabled={disabled}
        startIcon={
          <ScrIcon
            iconId={submitIconId ? submitIconId : 72}
            size="small"
            color="white"
          />
        }
        aria-label="søk">
        {submitLabel ? submitLabel : 'Søk'}
      </LoadingButton>
    </>
  );
};

export default SearchFormButtons;

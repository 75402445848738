import { ActionEnum } from 'features/notification';
import {
  addNotification,
  dismissAllNotifications,
} from 'features/notification/notificationSlice';
import { FetchStatus } from 'features/utils';

import { FormState } from './interface';

export const initialFetchStatus: FetchStatus = {
  loading: false,
};

export enum SearchType {
  ByLogistikkpartnerForsendelseId,
  ByMeldingId,
}

export const initialFormState: FormState = Object.freeze({
  logistikkpartnerForsendelseId: '',
  meldingId: '',
});

export const dispatchNotification = (
  dispatch: Function,
  message: string,
  variant: 'info' | 'error',
  refresh?: boolean
) => {
  dispatch(dismissAllNotifications());
  dispatch(
    addNotification({
      message: { text: message },
      variant: variant,
      action: refresh
        ? {
            text: 'Prøv på nytt',
            clickAction: { action: ActionEnum.Refresh },
          }
        : undefined,
    })
  );
};

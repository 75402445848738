import { addNotification } from 'features/notification/notificationSlice';
import { useDispatch } from 'react-redux';

async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return new Promise<void>((resolve, reject) => {
      const copied = document.execCommand('copy', true, text);
      if (copied) {
        resolve();
      } else {
        reject('Kunne ikke kopiere text');
      }
    });
  }
}

const useCopyTextToClipboard = () => {
  const dispatch = useDispatch();

  const copyText = async (text: string) => {
    try {
      await copyTextToClipboard(text);
      dispatch(
        addNotification({
          message: { text: 'Tekst kopiert til utklippstavlen' },
          variant: 'info',
        })
      );
    } catch (e) {
      addNotification({
        message: { text: (e as Error).message },
        variant: 'error',
      });
    }
  };
  return [copyText];
};

export default useCopyTextToClipboard;

import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ScrIcon } from 'components';

import Props from './interface';

const SrcPageHeader: React.FC<Props> = ({ iconId, h1Text, h2Text }: Props) => {
  const theme = useTheme();
  const headerSpacing = { pb: { xs: 2, xl: 4 }, mt: { xs: -2, md: 0 } };

  if (iconId)
    return (
      <Grid container alignItems="center" sx={headerSpacing}>
        <Grid container item xs={5} justifyContent="flex-end">
          <Box sx={{ paddingRight: theme.spacing(1) }}>
            <ScrIcon
              iconId={iconId!}
              size="xl"
              color={theme.palette.common.white}
              bgcolor={theme.palette.primary.main}
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Grid container item alignItems="flex-start">
            <Typography color="primary" variant={'h6'} component="h1">
              {h1Text}
            </Typography>
          </Grid>
          {h2Text && (
            <Grid item>
              <Typography color="primary" component="h2">
                {h2Text}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );

  return (
    <Box sx={headerSpacing}>
      <Typography
        color="primary"
        variant="h6"
        component="h1"
        sx={{ display: 'flex', justifyContent: 'center' }}>
        {h1Text}
      </Typography>
      {h2Text && (
        <Typography color="primary" component="h2">
          {h2Text}
        </Typography>
      )}
    </Box>
  );
};

export default SrcPageHeader;

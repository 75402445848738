import { CircularProgress } from '@mui/material';
import { ErrorBoundary } from 'components';
import { ErrorTypeEnum } from 'components/Error/interface';
import { ReactElement, Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import { enheterRoutes } from './enheterRoutes';
import { helsepersonellRoutes } from './helsepersonellRoutes';
import { hendelsekontrollRoutes } from './hendelsekontrollRoutes';
import { invitasjonOgInklusjonRoutes } from './invitasjonOgInklusjonRoutes';
import Root from './Root';
import {
  BRUKERE_PATH,
  BRUKER_PATH,
  DOKUMENTER_PATH,
  EKSTERNT_PERSONSOK_PATH,
  ENHETER_PATH,
  HELSEPERSONELL_LANDINGSIDE,
  HENDELSEKONTROLL_LANDINGSIDE_PATH,
  INVITASJON_OG_INKLUSJON_PATH,
  KAPASITET_PATH,
  LOGGET_UT_PATH,
  OPPFOLGING,
  PERSONOPPSLAG_PATH,
  PERSON_PATH,
  SENTERTILKNYTNING_PATH,
  SENTRALADMINSØK_ROUTE,
  SENTRALADMIN_EKSTERNE_MELDINGER,
  SENTRALADMIN_HELSEMELDINGER,
  SENTRALADMIN_HELSEMELDINGER_FEIL,
  SENTRALADMIN_HELSEMELDINGER_FEIL_SOK,
  SENTRALADMIN_HELSEMELDINGER_SOK,
  SENTRALADMIN_LOGISITKKPARTNER,
  SENTRALADMIN_LOGISITKKPARTNER_FEIL,
  SENTRALADMIN_LOGISITKKPARTNER_SOK,
  STYLE_GUIDE_PATH,
  SYSTEMINFORMASJON_PATH,
  SYSTEMTABELLER_PATH,
  UTSENDINGSPAUSE_PAGE_PATH,
  UTSENDINGTYPE_PATH,
  ADMINISTRASJON_LANDINGSIDE,
  PROFIL_PATH,
  SENTRALADMIN_HELSEMELDINGER_FHIR_SOK,
} from './routeConstants';
import { sentraladminSokRoutes } from './sentraladminSokRoutes';
import { utsendingspauseRoutes } from './utsendingspauseRoutes';
import { administrasjonRoutes } from './administrasjonRoutes';

const DokumentPage = lazy(
  () => import('features/brevmaladmin/dokument/DokumentPage')
);
const UtsendingTypePage = lazy(
  () => import('features/brevmaladmin/utsending/UtsendingTypePage')
);
const OppfølgingPage = lazy(
  () => import('features/senteradmin/oppfølging/OppfølgingPage')
);
const SysteminformasjonSide = lazy(
  () => import('features/systeminformasjon/SysteminformasjonSide')
);

const DashboardPage = lazy(() => import('features/dashboard/DashboardPage'));
const ProfilPage = lazy(() => import('features/dashboard/ProfilPage'));
const KapasitetPage = lazy(
  () => import('features/senteradmin/kapasitet/KapasitetPage')
);

const BrukerSearchPage = lazy(
  () => import('features/bruker/search/BrukerSearchPage')
);
const BrukerEditorPage = lazy(
  () => import('features/bruker/editor/BrukerEditorPage')
);
const DisplayError = lazy(() => import('components/Error/DisplayError'));
const DeltakerSearchPage = lazy(
  () => import('features/deltaker/search/DeltakerSearchPage')
);
const DeltakerPage = lazy(
  () => import('features/deltaker/details/DeltakerPage')
);
const Sentertilknytning = lazy(
  () => import('features/senteradmin/sentertilknytning/SentertilknytningPage')
);
const EksterneMeldingerPage = lazy(
  () => import('features/sentraladmin/eksterneMeldinger/EksterneMeldingerPage')
);
const StyleGuide = lazy(() => import('components/StyleGuide/StyleGuide'));

const Systemtabeller = lazy(
  () => import('features/senteradmin/systemtabeller/SystemtabellerPage')
);
const EksternPersonsokPage = lazy(
  () => import('features/senteradmin/eksternPersonsok/EksternPersonsokPage')
);

const GoBack = lazy(() => import('app/routes/GoBack'));
export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <DisplayError errorType={ErrorTypeEnum.NotFound} />,
    children: [
      {
        errorElement: <DisplayError errorType={ErrorTypeEnum.NotFound} />,
        children: [
          {
            index: true,
            element: (
              <AuthRoute>
                <DashboardPage />
              </AuthRoute>
            ),
          },
          {
            path: PROFIL_PATH,
            element: (
              <AuthRoute>
                <GoBack />
                <ProfilPage />
              </AuthRoute>
            ),
          },
          {
            path: BRUKERE_PATH,
            element: (
              <AuthRoute
                pageProps={{
                  description:
                    'Søk etter brukere, enten ved direkte oppslag på fødselsnummer, eller søkekriterier',
                }}>
                <BrukerSearchPage />
              </AuthRoute>
            ),
          },
          {
            path: BRUKER_PATH,
            element: (
              <AuthRoute>
                <BrukerEditorPage />
              </AuthRoute>
            ),
          },
          {
            path: SENTERTILKNYTNING_PATH,
            element: (
              <AuthRoute>
                <Sentertilknytning />
              </AuthRoute>
            ),
          },
          {
            path: SYSTEMTABELLER_PATH,
            element: (
              <AuthRoute>
                <Systemtabeller />
              </AuthRoute>
            ),
          },
          {
            path: PERSONOPPSLAG_PATH,
            element: (
              <AuthRoute
                pageProps={{
                  description:
                    'Søk etter personer, enten ved direkte oppslag på fødselsnummer eller Intern-ID, eller søkekriterier',
                }}>
                <DeltakerSearchPage />
              </AuthRoute>
            ),
          },
          {
            path: PERSON_PATH,
            element: (
              <AuthRoute pageProps={{ description: 'Persondetaljer' }}>
                <DeltakerPage />
              </AuthRoute>
            ),
          },
          {
            path: `/${ENHETER_PATH}`,
            errorElement: (
              <ErrorBoundary>
                <DisplayError errorType={ErrorTypeEnum.NotFound} />
              </ErrorBoundary>
            ),
            children: enheterRoutes,
          },

          {
            path: KAPASITET_PATH,
            element: (
              <AuthRoute>
                <KapasitetPage />
              </AuthRoute>
            ),
          },
          {
            path: INVITASJON_OG_INKLUSJON_PATH,
            errorElement: (
              <ErrorBoundary>
                <DisplayError errorType={ErrorTypeEnum.NotFound} />
              </ErrorBoundary>
            ),
            children: invitasjonOgInklusjonRoutes,
          },
          {
            path: `/${HELSEPERSONELL_LANDINGSIDE}`,
            errorElement: (
              <ErrorBoundary>
                <DisplayError errorType={ErrorTypeEnum.NotFound} />
              </ErrorBoundary>
            ),
            children: helsepersonellRoutes,
          },
          {
            path: `/${ADMINISTRASJON_LANDINGSIDE}`,
            errorElement: (
              <ErrorBoundary>
                <DisplayError errorType={ErrorTypeEnum.NotFound} />
              </ErrorBoundary>
            ),
            children: administrasjonRoutes,
          },
          {
            path: `/${OPPFOLGING}`,
            element: (
              <AuthRoute>
                <OppfølgingPage />
              </AuthRoute>
            ),
          },
          {
            path: `/${HENDELSEKONTROLL_LANDINGSIDE_PATH}`,
            errorElement: (
              <ErrorBoundary>
                <DisplayError errorType={ErrorTypeEnum.NotFound} />
              </ErrorBoundary>
            ),
            children: hendelsekontrollRoutes,
          },
          {
            path: SENTRALADMINSØK_ROUTE,
            errorElement: (
              <ErrorBoundary>
                <DisplayError errorType={ErrorTypeEnum.NotCreated} />
              </ErrorBoundary>
            ),
            children: sentraladminSokRoutes,
          },
          {
            path: SENTRALADMIN_EKSTERNE_MELDINGER,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage tab="" />
              </AuthRoute>
            ),
          },
          {
            path: SENTRALADMIN_HELSEMELDINGER,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage tab={SENTRALADMIN_HELSEMELDINGER} />
              </AuthRoute>
            ),
          },
          {
            path: SENTRALADMIN_HELSEMELDINGER_SOK,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage tab={SENTRALADMIN_HELSEMELDINGER_SOK} />
              </AuthRoute>
            ),
          },
          {
            path: SENTRALADMIN_HELSEMELDINGER_FEIL,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage tab={SENTRALADMIN_HELSEMELDINGER_FEIL} />
              </AuthRoute>
            ),
          },
          {
            path: SENTRALADMIN_HELSEMELDINGER_FEIL_SOK,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage
                  tab={SENTRALADMIN_HELSEMELDINGER_FEIL_SOK}
                />
              </AuthRoute>
            ),
          },
          {
            path: SENTRALADMIN_LOGISITKKPARTNER,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage tab={SENTRALADMIN_LOGISITKKPARTNER} />
              </AuthRoute>
            ),
          },
          {
            path: SENTRALADMIN_LOGISITKKPARTNER_SOK,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage
                  tab={SENTRALADMIN_LOGISITKKPARTNER_SOK}
                />
              </AuthRoute>
            ),
          },
          {
            path: SENTRALADMIN_LOGISITKKPARTNER_FEIL,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage
                  tab={SENTRALADMIN_LOGISITKKPARTNER_FEIL}
                />
              </AuthRoute>
            ),
          },
          {
            path: SENTRALADMIN_HELSEMELDINGER_FHIR_SOK,
            element: (
              <AuthRoute>
                <EksterneMeldingerPage
                  tab={SENTRALADMIN_HELSEMELDINGER_FHIR_SOK}
                />
              </AuthRoute>
            ),
          },
          {
            path: `/${EKSTERNT_PERSONSOK_PATH}`,
            element: (
              <AuthRoute>
                <EksternPersonsokPage />
              </AuthRoute>
            ),
          },
          {
            path: `/${DOKUMENTER_PATH}`,
            element: (
              <AuthRoute>
                <DokumentPage />
              </AuthRoute>
            ),
          },
          {
            path: `/${UTSENDINGTYPE_PATH}`,
            element: (
              <AuthRoute>
                <UtsendingTypePage />
              </AuthRoute>
            ),
          },
          {
            path: UTSENDINGSPAUSE_PAGE_PATH,
            errorElement: (
              <ErrorBoundary>
                <DisplayError errorType={ErrorTypeEnum.NotCreated} />
              </ErrorBoundary>
            ),
            children: utsendingspauseRoutes,
          },
          {
            path: `/${SYSTEMINFORMASJON_PATH}`,
            element: (
              <AuthRoute>
                <GoBack />
                <SysteminformasjonSide />
              </AuthRoute>
            ),
          },
          {
            path: `${LOGGET_UT_PATH}`,
            element: (
              <AuthRoute>
                <DisplayError errorType={ErrorTypeEnum.LoggedOut} />
              </AuthRoute>
            ),
          },
          {
            path: STYLE_GUIDE_PATH,
            element: (
              <AuthRoute>
                <StyleGuide />
              </AuthRoute>
            ),
          },
        ],
      },
    ],
  },
]);
const MainRoutes = (): ReactElement => {
  return (
    <Suspense
      fallback={<CircularProgress aria-label="laster side-animasjon" />}>
      <RouterProvider
        router={router}
        fallbackElement={
          <AuthRoute>
            <DisplayError errorType={ErrorTypeEnum.NotCreated} />
          </AuthRoute>
        }
      />
    </Suspense>
  );
};

export default MainRoutes;

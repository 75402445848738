import { Divider, Grid, TextField, Theme, useMediaQuery } from '@mui/material';
import { ScrWrapper } from 'components';
import SearchFormButtons from 'components/SearchForm/SearchFormButtons';
import SearchFormHeading from 'components/SearchForm/SearchFormHeading';
import { useEffect, useState } from 'react';

import { searchDividerSpacing } from '../../../../utils/styles/styles';
import { SearchType } from './utils';
import { Props } from './interface';

const SØK_ANDVORD_STATUS = 'Søk Andvord Status';

const AndvordStatusSearchForm: React.FC<Props> = ({
  isSearching,
  formState,
  handleChange,
  handleSubmit,
  reset,
}) => {
  const [searchTypeActive, setSearchTypeActive] = useState<SearchType>();

  useEffect(() => {
    if (formState.logistikkpartnerForsendelseId?.length > 0) {
      setSearchTypeActive(SearchType.ByLogistikkpartnerForsendelseId);
    } else if (formState.meldingId?.length > 0) {
      setSearchTypeActive(SearchType.ByMeldingId);
    } else {
      setSearchTypeActive(undefined);
    }
  }, [formState, setSearchTypeActive]);

  const isLgUpScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('lg')
  );

  const resetForm = () => {
    reset();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onReset={resetForm}
        noValidate
        autoComplete="off"
        role="search">
        <ScrWrapper variant="secondary" sx={{ p: 2, mb: 2 }}>
          <Grid container item lg={12} alignItems="center">
            <Grid container item xs={12} lg={2} sx={{ maxHeight: 158 }}>
              <Grid item xs={12}>
                <SearchFormHeading
                  text="Logistikkpartner Forsendelse Id"
                  ariaLabel={'headingAriaLabel' + SØK_ANDVORD_STATUS}
                />
              </Grid>
              <Grid item xs={4} md={3} lg={12}>
                <TextField
                  sx={{ mt: 1 }}
                  label="Logistikkpartner Forsendelse Id"
                  name="logistikkpartnerForsendelseId"
                  error={false}
                  required={false}
                  id="logistikkpartnerForsendelseId"
                  onChange={handleChange}
                  disabled={searchTypeActive === SearchType.ByMeldingId}
                  value={
                    searchTypeActive === SearchType.ByMeldingId
                      ? ''
                      : formState.logistikkpartnerForsendelseId
                  }
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              lg={1}
              sx={{ ...searchDividerSpacing, maxHeight: 158 }}>
              <Divider orientation={isLgUpScreen ? 'vertical' : 'horizontal'}>
                eller
              </Divider>
            </Grid>

            <Grid container item xs={12} lg={3} sx={{ maxHeight: 158 }}>
              <Grid item xs={12}>
                <SearchFormHeading
                  text="Melding Id"
                  ariaLabel={'headingAriaLabel' + SØK_ANDVORD_STATUS}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Melding Id"
                  name="meldingId"
                  error={false}
                  required={false}
                  id="meldingId"
                  onChange={handleChange}
                  disabled={
                    searchTypeActive ===
                    SearchType.ByLogistikkpartnerForsendelseId
                  }
                  value={
                    searchTypeActive ===
                    SearchType.ByLogistikkpartnerForsendelseId
                      ? ''
                      : formState.meldingId
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </ScrWrapper>

        <Grid
          container
          item
          lg={12}
          alignItems="flex-start"
          sx={{ pl: { xl: 2 }, pt: { xs: 2, xl: 0 } }}>
          <Grid container item lg={12}>
            <SearchFormButtons
              buttonIdNamePrefix={'andvordstatus-search-form'}
              isSearching={isSearching}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AndvordStatusSearchForm;
